#contactPage {
  min-height: calc(100vh - 4rem);
  width: 100vw;
  max-width: 60rem;
  margin: 0 auto;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.contactPageTitle {
  font-size: 3rem;
  margin-bottom: 1rem;
}

#clients {
  padding: 2rem;
}

.contactDesc {
  padding: 1rem;
  font-size: medium;
  font-weight: 300;
}

.contactForm {
  margin: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 90vw;
  max-width: 60rem;
}

.name,
.email,
.msg,
.phone {
  font-size: medium;
  width: 100%;
  max-width: 40rem;
  margin: 0.5rem;
  padding: 0.5rem 1rem;
  color: white;
  border: none;
  border-radius: 0.5rem;
  background: rgb(40, 40, 40);
}

.submitBtn {
  background: white;
  border: none;
  border-radius: 0.5rem;
  margin: 2rem;
  padding: 0.75rem 3.5rem;
  cursor: pointer;
  color: black;
}
.links {
  display: flex;
  flex-wrap: wrap;
}
.link {
  object-fit: cover;
  height: 3rem;
  width: 3rem;
  margin: 0 0.75rem;
  cursor: pointer;
  border-radius: 50%;
}

/* yorumlar */

#yorumlar-container {
  max-width: 1200px;
  margin: 0 auto;
  text-align: center;
  margin-bottom: 5rem;
}

.yorum-card {
  background: rgb(50, 50, 50);
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin: 10px;
  padding: 20px;
  display: inline-block;
  vertical-align: top;
  width: calc(25% - 20px);
  height: 410px;
}

.yorum-card img {
  border-radius: 50%;
  margin-bottom: 10px;
}

.yorum-card h3 {
  color: #3fa0c3;
  margin-bottom: 10px;
}

.yorum-card p {
  color: #ffdc21;
}
.yorum-card .card-desc {
  color: white;
}

@media screen and (max-width: 1024px) {
  .yorum-card {
    width: calc(50% - 40px);
    height: auto;
  }
  .submitBtn {
    color: black;
  }
}

@media screen and (max-width: 768px) {
  .yorum-card {
    width: calc(100% - 40px);
    height: auto;
  }
  .submitBtn {
    color: black;
  }
}
