footer {
  width: 100vw;
  height: 4rem;
  background: rgb(40, 40, 40);
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 300;
  font-size: small;
}
footer a {
  color: white;
}
