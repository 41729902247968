#intro {
  height: calc(100vh - 5rem);
  width: 100vw;
  max-width: 75rem;
  margin: 0 auto;
  overflow: hidden;
  position: relative;
  background: transparent;
}
.bg {
  position: absolute;
  top: -3rem;
  right: 0;
  z-index: -1;
  object-fit: cover;
  height: 100vh;
  border-radius: 15rem;
}
.introContent {
  height: 100vh;
  width: 100vw;
  padding: 2rem;
  font-size: 3rem;
  font-weight: 700;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.hello {
  font-size: 1.75rem;
  font-weight: 100;
}
.introName {
  color: yellow;
}
.introPara {
  font-size: medium;
  font-weight: 300;
  letter-spacing: 1px;
}
.btn {
  background-color: white;
  margin: 1rem 0;
  padding: 0.75rem 1.5rem;
  border-radius: 2rem;
  border: none;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.btnImg {
  object-fit: cover;
  margin: 0 0.25rem;
  height: 2rem;
}
.btn a {
  color: black;
  z-index: 999;
}
@media screen and (max-width: 840px) {
  .bg {
    right: -30vw;
  }
  .introContent {
    font-size: 10vw;
  }
  .hello {
    font-size: 4.5vw;
  }
}
@media screen and (max-width: 480px) {
  .bg {
    right: -60vw;
  }
}
